import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import sanitizeHtml from "sanitize-html"

import * as inlineStyles from "../components/Layout/inlineStyles"

export default function PageTemplate(props) {
  const page = props.data.wordpressPage
  return (
    <Layout>
      <SEO title={page.title} />
      <div className="container" style={inlineStyles.singleItemContainer}>
        <h1
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(page.title.replace(/\u2028/g, " ")),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(page.content.replace(/\u2028/g, " ")),
          }}
        />
      </div>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
